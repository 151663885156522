<template>
    <div class="post-card p-5">
        <div v-if="showMessage" class="alert" :class="success ? 'alert-success' : 'alert-danger'">
            <span v-if="success">The message has been successfully sent</span>
            <span v-else>Something went wrong, please try again latter</span>
        </div>
        <h1 class="mb-3">Verify Your Email Address</h1>
        <div>
           Before proceeding, please check your email for a verification link.
            If you did not receive the email
            <a href="" :class="{'is-disabled': showMessage}" @click.prevent="resendVerify">click here to request another
                <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterVerify",

    data() {
        return {
            isLoading: false,
            success: false,
            showMessage: false
        }
    },

    methods: {
        async resendVerify() {
            this.isLoading = true
            await axios.post("/email/verification-notification").then(() => {
                this.success = true;
            }).catch(() => {
                this.success = false
            }).finally(() => {
                this.isLoading = false;
                this.showMessage = true;
                setTimeout(() => this.showMessage = false, 3000);
            });
        },
    }
}
</script>

<style scoped>
.is-disabled {
    pointer-events: none;
}

</style>
