<template>
    <div class="post-card">
        <div class="card-top d-flex align-items-center">
            <user-info-card :user="post" />
            <div class="post-time p-2" :title="'UTC: ' + post.created_at">{{post.created_ago}}</div>
            <div class="follow-btn ml-auto">
                <follow-button
                    v-if="showFollowButton && (!user || post.username !== user.username)"
                    :is-followed.sync="post.is_author_followed"
                    :username="post.username">
                </follow-button>
            </div>
        </div>
        <div class="card-title-section">
            <div class="post-details">
                <p class="m-0">{{post.description}}</p>
            </div>
        </div>
        <div v-if="post.type === 2">
            <div class="embed-responsive embed-responsive-16by9">
                <iframe
                    class="embed-responsive-item"
                    :src="'https://www.youtube.com/embed/' + post.source "
                >
                </iframe>
            </div>
        </div>
        <div v-else-if="post.type === 4 || post.type === 5" class="post-image">
            <a :href="post.url">
                <video class="w-100" :src="post.source" controls></video>
            </a>
        </div>
        <div v-else class="post-image">
            <a :href="post.url"><img :src="post.source" alt=""/></a>
        </div>
        <div class="card-bottom row m-0 align-items-center">
            <div class="col-7 col-xl-4 p-0">
                <post-vote :post="post" />
            </div>
            <div class="col d-flex p-0 align-items-center">
                <a :href="post.url + '#comments'">
                    <icon
                        name="icon-comment"
                        class-name=""
                        width="40px"
                        height="40px"
                        color="#bdbdbd"
                    />
                </a>
                <span class="count">{{thousandFormatter(post.comments)}}</span>
            </div>
            <div class="col col-sm-auto p-0">
                <post-meta :post="post" />
            </div>
        </div>
        <div class="card-source-text">
            <div class="post-source-text">
                <p class="m-0">{{post.source_text}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import PostMeta from "./PostMeta";
import PostVote from "./PostVote";
import Icon from "./Icon";
export default {
    name: "SinglePost",
    components: {Icon, PostVote, PostMeta},
    props: {
        post: {
            type: Object,
            required: true
        },
        showFollowButton: {
            type: Boolean,
            default: true
        },
        showNoPostsInfo: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        }
    },
    methods: {
        thousandFormatter(num) {
            return Math.abs(num) > 999
                ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k'
                : Math.sign(num) * Math.abs(num)
        }
    }
}
</script>

<style scoped>
.post-source-text {

}
</style>
