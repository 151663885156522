<template>
    <transition name="fade">
        <div v-show="unfollowModalShown" class="memehead-overlay" style="background: transparent">
            <div class="modal-box">
                <div class="modal-close" @click="hideUnfollowModal">
                    <button></button>
                </div>
                <div class="modal-container">
                    <div class="modal-title">Are you sure?</div>
                    <div class="modal-data">
                        You are about to unfollow
                        <strong v-if="userToUnfollow">{{'@' + userToUnfollow}}.</strong><br>
                        Are you sure that you want to proceed?
                        <div class="d-flex mt-5">
                            <button class="btn btn-blue w-100 mr-2" @click="unfollow">Yes</button>
                            <button class="btn btn-danger w-100" @click="hideUnfollowModal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import FollowersAPI from "../api/FollowersAPI";

    export default {
        name: "UnfollowModal",
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            unfollowModalShown() {
                return this.$store.state.unfollowModalShown
            },
            userToUnfollow() {
                return this.$store.state.userToUnfollow
            },
        },
        methods: {
            hideUnfollowModal() {
                this.$store.commit('hideUnfollowModal')
            },
            unfollow() {
                this.hideUnfollowModal();
                FollowersAPI
                    .unfollow(this.userToUnfollow)
                    .then((res) => {
                        this.$store.commit('addUnfollowedUser', this.userToUnfollow)
                    })
            }
        }
    }
</script>
