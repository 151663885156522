<template>
    <div class="user-middle-row">
        <slot
            :scroll-left="scrollLeft"
            :scroll-right="scrollRight"
            :left="left"
        >
        </slot>
    </div>
</template>

<script>
    export default {
        name: "ProfileInfo",
        data() {
            return {
                left: 0
            }
        },
        methods: {
            scrollLeft() {
                if (this.left < 0) {
                    this.left += 70;
                }
            },
            scrollRight() {
                if (this.left > -140) {
                    this.left -= 70;
                }
            },
        }
    }
</script>
