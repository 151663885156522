export default {
    state: {
        currentUser: null,

        headerMenuShown: false,

        mobileCategoriesShown: false,

        headerNotificationsShown: false,

        authModalShown: false,

        submitModalShown: false,

        unfollowModalShown: false,
        userToUnfollow: null,
        unfollowedUsers: [],
        followedUsers: [],

        postToDelete: null,
        commentToDelete: null,
        commentToReport: null,
        postToReport: null,
        draftComment: null
    },
    mutations: {
        toggleHeaderMenu(state) {
            state.headerMenuShown = !state.headerMenuShown;
            if (state.headerMenuShown) {
                state.headerNotificationsShown = false
            }
        },
        toggleHeaderNotifications(state) {
            state.headerNotificationsShown = !state.headerNotificationsShown;
            if (state.headerNotificationsShown) {
                state.headerMenuShown = false
            }
        },
        showAuthModal(state) {
            state.authModalShown = true;
        },
        hideAuthModal(state) {
            state.authModalShown = false;
        },
        showSubmitModal(state) {
            state.submitModalShown = true;
        },
        hideSubmitModal(state) {
            state.submitModalShown = false;
        },
        storeUser(state, data) {
            state.currentUser = data;
        },

        showUnfollowModal(state) {
            state.unfollowModalShown = true;
        },
        hideUnfollowModal(state) {
            state.unfollowModalShown = false;
        },
        setUserToUnfollow(state, user) {
          state.userToUnfollow = user;
        },
        addUnfollowedUser(state, user) {
            state.unfollowedUsers.push(user);
        },
        addFollowedUser(state, user) {
            state.followedUsers.push(user);
        },
        toggleMobileCategories(state) {
            state.mobileCategoriesShown = !state.mobileCategoriesShown;
        },
        setPostToDelete(state, postId) {
            state.postToDelete = postId;
        },
        unsetPostToDelete(state, postId) {
            state.postToDelete = null;
        },
        setDraftComment(state, data) {
            state.draftComment = data;
        },
        setCommentToDelete(state, data) {
            state.commentToDelete = data;
        },
        unsetCommentToDelete(state) {
            state.commentToDelete = null;
        },
        setCommentToReport(state, data) {
            state.commentToReport = data;
        },
        unsetCommentToReport(state) {
            state.commentToReport = null;
        },
        setPostToReport(state, data) {
            state.postToReport = data;
        },
        unsetPostToReport(state) {
            state.postToReport = null;
        }
    }
}
