<template>
    <transition name="fade">
        <div v-show="submitModalShown" class="memehead-overlay">
            <div class="modal-box-wrp upload-post-modal">
                <div class="modal-box" :class="{'w-650': step === 1, 'w-450': step !== 1}">
                    <div class="modal-close" @click="hideSubmitModal">
                        <button></button>
                    </div>
                    <div v-if="step === 0" class="modal-container">
                        <div class="modal-title">Upload a Post</div>
                        <p class="subtitle mb-3">Upload an image or video, or create your own</p>
                        <post-media-upload
                            :isLoading="this.isParseFileLoading"
                            @uploadedFile="processUploadedFile"
                            @parseFile="parseFileUrl"
                        />

                        <div class="upload-box flex-column pb-4 pt-4">
                            <div class="text-center">
                                <img src="images/logo.svg" alt="" width="80px" class="mt-3 mb-3"/>
                                <h6 class="">Create Meme</h6>
                                <div class="upload-box-bottom">
                                    <p class="float-none pb-4 pt-3">
                                        Choose from popular meme templates, <br> or create a meme using your own
                                        image
                                    </p>
                                    <div class="file-btn mt-2 mb-2">Create</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="step === 1" class="modal-container">
                        <div class="modal-title mb-3">Add details to your post</div>
                        <div class="no-label">
                            <p>A descriptive title and tags can help your post get more views.</p>
                            <div v-if="formErrors.description" class="form-group">
                                <div class="invalid-feedback" style="display: block;">
                                    {{ formErrors.description }}
                                </div>
                            </div>
                        </div>
                        <div class="textarea-preview">
                            <textarea
                                v-model="newPost.description"
                                class="form-control mb-3"
                                rows="3"
                                placeholder="Enter description (optional)"
                                ref="textInput"
                            >
                            </textarea>
                            <user-mention-list
                                v-if="$refs.textInput"
                                :input="newPost.description"
                                :element="$refs.textInput"
                                @userClicked="mentionClicked"
                            ></user-mention-list>
                        </div>

                        <!-- File preview start -->
                        <div class="image-preview" v-if="memePreview">
                            <img
                                v-if="fileType === 'image'"
                                :src="memePreview"
                                alt=""
                            />
                            <video class="w-100" v-if="fileType === 'video'" controls>
                                <source :src=this.memePreview>
                            </video>
                            <div class="clear" @click="handleClearPreview">
                                <img src="/images/icon-close-gray-2.svg" alt=""/>
                            </div>
                        </div>
                        <post-media-upload v-else
                           :isLoading="this.isParseFileLoading"
                           @uploadedFile="processUploadedFile"
                           @parseFile="parseFileUrl"
                        />
                        <!-- File preview end -->

                        <div class="clearfix"></div>
                        <div class="mt-4">
                            <multiselect
                                v-model="newPost.tags"
                                placeholder="Enter tags"
                                :options="tagOptions"
                                :multiple="true"
                                :searchable="true"
                                :loading="isLoadingTags"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :options-limit="6"
                                :limit="10"
                                :max="10"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="true"
                                :taggable="true"
                                :custom-label="tagLabel"
                                track-by="name"
                                no-options="No tags found"
                                @search-change="findTag"
                                @tag="addTag"
                            >
                                <template slot="noOptions">
                                    Start typing to search for tags or to add a new one...
                                </template>
                            </multiselect>
                        </div>
                        <div class="clearfix"></div>
                        <div class="form-group modal-tag-row">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="check-box-row">
                                        <label class="container-checkbox">This is sensitive
                                            <input v-model="newPost.is_sensitive" type="checkbox">
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="check-box-row">
                                        <label class="container-checkbox">Attribute original poster
                                            <input v-model="attributeOriginalPoster" type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="attributeOriginalPoster" class="form-group">
                            <label for="source-link">Source Link</label>
                            <input v-model="newPost.original_author_url" type="text" class="form-control"
                                   id="source-link"
                                   placeholder="https://www.postize.com/dogs-first-christmas">
                        </div>
                        <div class="upload-form-btn">
                            <ul>
                                <li>
                                    <button class="btn-rainbow-border full-width" @click="backToStart"><span>back</span>
                                    </button>
                                </li>
                                <li>
                                    <button class="btn-rainbow full-width" @click="goToLastStep">next</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="step === 2" class="modal-container">
                        <div class="modal-title">Choose a category</div>
                        <p class="m-0">
                            Submit to the right section to make sure your post gets the right exposure it
                            deserves!
                        </p>
                        <div class="section-choice-box">
                            <div class="choice-select-row">
                                <ul>
                                    <li v-for="cat in categories">
                                        <label class="container-checkbox"
                                               :class="{ 'checked': newPost.category === cat.slug }">
                                            <span class="choie-icon">
                                                <img :src="'images/icon-' + cat.slug + '.svg'" alt="">
                                            </span>
                                            {{ cat.name }}
                                            <span class="select-checkbox">
                                                  <input
                                                      v-model="newPost.category"
                                                      type="radio"
                                                      name="new-meme-category"
                                                      :value="cat.slug"
                                                  >
                                                  <span class="checkmark"></span>
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="upload-form-btn">
                            <ul>
                                <li>
                                    <button class="btn-rainbow-border full-width" @click="step = 1"><span>back</span>
                                    </button>
                                </li>
                                <li>
                                    <button class="btn-rainbow full-width" :disabled="isSubmitting" @click="submitPost">
                                        <i v-if="isSubmitting" class="fa fa-circle-o-notch fa-spin"></i>
                                        <span v-else>post</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import PostsAPI from "../api/PostsAPI";
import Multiselect from 'vue-multiselect'
import TagsAPI from "../api/TagsAPI";
import {debounce} from "lodash"
import categories from "../../../src/Static/categories.json"
import extractYoutubeVideoId from "../lib/extractYoutubeVideoId";
import createVideoThumbnail from "../lib/createVideoThumbnail";
import PostMediaUpload from "./PostMediaUpload";

const UPLOADED_IMAGE = 0;
const PASTED_IMAGE = 1;
const YOUTUBE_VIDEO = 2;
const CREATED_MEME_IMAGE = 3;
const UPLOADED_VIDEO = 4;
const PASTED_VIDEO = 5;

const fileReader = new FileReader();

export default {
    name: "SubmitPostModal",
    components: {
        PostMediaUpload,
        Multiselect
    },
    data() {
        return {
            formErrors: {description: ""},
            step: 0,
            method: UPLOADED_IMAGE,
            attributeOriginalPoster: false,
            memePreview: null,
            fileType: '',
            isParseFileLoading: false,

            newPost: {
                description: "",
                file: null,
                image_url: null,
                youtube_id: null,
                type: 0, // @see PostType.php
                tags: [],
                category: "funny",
                is_sensitive: false,
                original_author_url: null,
                videoThumbnail: null
            },

            tagOptions: [],
            isLoadingTags: false,
            categories: categories,
            isSubmitting: false,
            isClearedPreview: false,
        }
    },
    computed: {
        submitModalShown() {
            return this.$store.state.submitModalShown
        },
        isFileImage() {
            return this.fileType === 'image';
        },
        isFileVideo() {
            return this.fileType === 'video';
        },
    },
    methods: {
        async submitPost() {
            this.isSubmitting = true;
            if (this.isFileVideo) {
                await createVideoThumbnail(this.newPost.file).then(result => {
                    this.newPost.videoThumbnail = result;
                }).catch((error) => {
                    this.$toast.error(error);
                });
            }
            await PostsAPI
                .uploadPost(this.newPost)
                .then((postUrl) => {
                    window.location.href = postUrl;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.responseMessage);
                    this.isSubmitting = false;
                })
        },
        processUploadedFile(file) {
            this.fileType = file['type'].split('/')[0];
            fileReader.onload = ((e) => {
                this.newPost.type = this.newPost.type || (this.isFileVideo ? UPLOADED_VIDEO : UPLOADED_IMAGE )
                this.memePreview = e.target.result;
                this.newPost.file = file;
                if (!this.isClearedPreview) {
                    this.step++;
                }
            });
            fileReader.onerror = (() => {
                this.$toast.error('There was an error opening your file. It may be corrupted');
            });
            fileReader.onabort = (() => {
                this.$toast.error('There was an error opening your file. It may be corrupted');
            });

            fileReader.readAsDataURL(file);
        },
        parseYoutubeUrl(youtubeId) {
            this.newPost.youtube_id = youtubeId;
            this.memePreview = `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
            this.newPost.type = YOUTUBE_VIDEO;
            this.fileType = 'image';
            if (!this.isClearedPreview) {
                this.step++;
            }
        },
        parseImageUrl(fileUrl) {
            const src = JSON.parse(JSON.stringify(fileUrl));
            this.newPost.image_url = src;
            this.memePreview = src;
            this.newPost.type = PASTED_IMAGE
            this.fileType = 'image';
            if (!this.isClearedPreview) {
                this.step++;
            }
        },
        async parseFileUrl(fileUrl) {
            this.isParseFileLoading = true;
            const youtubeId = extractYoutubeVideoId(fileUrl)
            if (youtubeId) {
                this.parseYoutubeUrl(youtubeId);
                return;
            }
            // Check if url is image file
            if (fileUrl.match(/\.(jpeg|jpg|gif|png|tiff|pjp|jfif|bmp|svg|xbm|dib|jxl|svgz|webp|ico|tif|pjpeg|avif|)$/) !== null) {
                this.parseImageUrl(fileUrl);
                return;
            }
            // Check if url is video file
            if (fileUrl.match(/\.(m4v|mp4|avi|)$/) !== null) {
                // Avoiding CORS Errors only for development
                if (location.hostname === 'memehead.test') {
                    fileUrl = 'https://cors-anywhere.herokuapp.com/' + fileUrl
                }
                await fetch(fileUrl, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then((res) => {
                    return res.blob();
                }).then((blob) => {
                    const file = new File([blob], "video.mp4", {
                        lastModified: new Date().getTime(),
                        type: 'video/mp4'
                    });
                    this.newPost.type = PASTED_VIDEO;
                    return this.processUploadedFile(file);
                }).catch(() => {
                    this.$toast.error('Failed to fetch video file');
                });
            }
            this.isParseFileLoading = false
        },
        findTag: debounce(async function (query) {
            if (query.length < 2) {
                this.tagOptions = [];
                return;
            }

            if (this.isLoadingTags) {
                return;
            }

            this.isLoadingTags = true;
            this.tagOptions = await TagsAPI.findTags(query);
            this.isLoadingTags = false;
        }, 400),
        addTag(newTag) {
            if (newTag.length < 2) {
                return;
            }

            if (this.newPost.tags.indexOf(newTag) > -1) {
                return;
            }

            let condition = /^[a-zA-Z0-9]+$/i;
            if (!condition.test(newTag)) {
                return;
            }
            this.newPost.tags.push({ name: newTag, count: 0 });
        },
        backToStart() {
            this.step = 0;
            this.formErrors.title = "";
            this.isClearedPreview = false;
            this.resetNewPost();
        },
        goToLastStep() {
            if (!this.newPost.description) {
                this.formErrors.description = "Description is required";
                return;
            }

            this.formErrors.title = "";
            this.step = 2;
        },
        resetNewPost() {
            this.newPost = {
                title: "",
                description: "",
                file: null,
                image_url: null,
                youtube_id: null,
                type: 0, // @see PostType.php
                tags: [],
                category: "funny",
                is_sensitive: false,
                original_author_url: null,
            };
        },
        hideSubmitModal() {
            this.$store.commit('hideSubmitModal');
        },
        mentionClicked(u) {
            let words = this.newPost.description.split(" ");
            if (words.length) {
                words.pop();
            }

            words.push(`@${u.username} `);
            this.newPost.description = words.join(" ");
            this.$refs.textInput.focus();
        },

        handleClearPreview() {
            this.memePreview = null;
            this.isClearedPreview = true;
            this.fileType = '';
        },
        tagLabel({ name, count }) {
            const formattedCount = count.toLocaleString('en-US');
            return `${name} (${formattedCount})`
        }
    }
}
</script>
