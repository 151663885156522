<template>
    <div class="comment-reply-box">
        <form @submit.prevent="submitComment">
            <div class="reply-box-right">
                <div>
                </div>
                <div class="upload-btn">
                    <label>
                        <input type="file" accept="image/x-png,image/gif,image/jpeg" hidden @change="fileSelected">
                    </label>
                </div>
                <div v-if="parentCommentId" class="cancel-btn">
                    <button class="btn-border" type="button" @click="$emit('cancel')">cancel</button>
                </div>
                <div class="post-btn">
                    <button class="btn-rainbow" type="submit" :disabled="isLoading">
                        <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                        <span v-else>post</span>
                    </button>
                </div>
            </div>
            <input
                v-model="newComment"
                type="text"
                class="form-control rounded-0"
                :placeholder="parentCommentId ? 'write reply to thread...' : 'write reply...'"
                ref="commentInput"
            >
            <p class="mt-3 mb-0" v-if="newCommentFile">Selected: <strong>{{ newCommentFile.name }}</strong></p>
            <user-mention-list
                v-if="$refs.commentInput"
                :input="newComment"
                :element="$refs.commentInput"
                @userClicked="mentionClicked"
            ></user-mention-list>
        </form>
    </div>
</template>

<script>
import CommentsAPI from "../api/CommentsAPI";
import UserMentionList from "./UserMentionList";

export default {
    name: "SubmitComment",
    components: {UserMentionList},
    props: {
        postId: {
            type: String,
            required: true
        },
        parentCommentId: {
            type: String,
            default: null,
            required: false
        },
        replyToUsername: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            newComment: "",
            newCommentFile: null,
            isLoading: false
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser
        }
    },

    created() {
        if (this.replyToUsername) {
            this.newComment = "@" + this.replyToUsername + " ";
        }
    },

    mounted() {
        this.$refs.commentInput.focus({
            preventScroll: true
        });
    },

    methods: {
        submitComment() {
            if (!this.user) {
                this.$store.commit('showAuthModal');
                if (this.newComment || this.parentCommentId || this.newCommentFile) {
                    let commentData = {
                        'postId': this.postId,
                        'comment': this.newComment,
                        'parentCommentId': this.parentCommentId,
                        'commentFile': this.newCommentFile
                    }
                    this.$store.commit('setDraftComment', commentData);
                }
                return;
            }
            this.isLoading = true;
            CommentsAPI
                .create(this.postId, this.newComment, this.parentCommentId, this.newCommentFile)
                .then((comment) => {
                    this.$emit('submitted', comment)
                    this.newComment = "";
                    this.newCommentFile = null;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        fileSelected(e) {
            this.newCommentFile = e.target.files[0];
        },
        mentionClicked(u) {
            let words = this.newComment.split(" ");
            if (words.length) {
                words.pop();
            }

            words.push(`@${u.username} `);
            this.newComment = words.join(" ");
            this.$refs.commentInput.focus();
        }
    }
}
</script>
