<template>
    <div>
        <div class="d-flex justify-content-between align-items-center pt-3">
            <user-info-card :user="comment.user"/>
            <div class="comment-manage-block d-flex">
                <div class="box like">
                    <a class="cbutton cbutton--effect-radomir" href="#" @click.prevent="likeComment">
                        <icon
                            name="icon-like"
                            width="20px"
                            height="20px"
                            :color=likeIconColor
                        />
                        <p class="m-0">{{ likes }}</p>
                    </a>
                </div>
                <div class="box dislike">
                    <a class="cbutton cbutton--effect-radomir" href="#" @click.prevent="dislikeComment">
                        <icon
                            name="icon-like"
                            class-name="icon-dislike"
                            width="20px"
                            height="20px"
                            :color=dislikeIconColor
                        />
                        <p class="m-0">{{ dislikes }}</p>
                    </a>
                </div>
                <comment-manage
                    v-if="this.$store.state.currentUser"
                    @edit="toggleShowEditComment"
                    class=""
                    :comment="comment"
                />
            </div>
        </div>
        <div v-if="showEditBox">
            <edit-comment
                :comment=comment
                @cancel=toggleShowEditComment
            />
        </div>
        <div class="comment-text" v-else v-html="comment.text_html"></div>
        <div v-if="comment.media" class="post-comment-image">
            <img :src="comment.media" alt=""/>
        </div>
        <div class="comment-reply-row">
            <a class="pr-2" href="#" @click.prevent="">{{ comment.created_ago }}</a>
            <a class="pl-2 pr-2"
               href="#"
               @click.prevent="$emit('reply-clicked')"
            >
                reply
            </a>
            <a
                v-if="showLoadReplies && (!comment.commentReplies.length && !comment.replies)"
                href="#"
                class="pl-2 pr-2"
                @click.prevent=""
            >
                no replies
            </a>
            <a
                v-if="showLoadReplies && (!comment.commentReplies.length && comment.replies)"
                href="#"
                class="pl-2"
                @click.prevent="$emit('get-replies-clicked')"
            >
                view replies ({{ comment.replies }})
            </a>
        </div>
    </div>
</template>

<script>
import CommentsAPI from "../api/CommentsAPI";
import UserInfoCard from "./UserInfoCard";
import CommentManage from "./CommentManage";
import SubmitComment from "./SubmitComment";
import Icon from "./Icon";

export default {
    name: "SingleComment",
    components: {Icon, SubmitComment, CommentManage, UserInfoCard},
    props: {
        comment: Object,
        showLoadReplies: Boolean
    },
    data() {
        return {
            likes: this.comment.likes,
            dislikes: this.comment.dislikes,
            reaction: this.comment.reaction,
            isLoading: false,
            isEdit: false,
            likeIconActive: 'url(#like-gradient)',
            dislikeIconActive: 'url(#dislike-gradient)',
        }
    },
    computed: {
        likeIconColor() {
            if (this.reaction !== 1) {
                return '#bdbdbd';
            }

            if (this.reaction === 1) {
                return this.likeIconActive;
            }
        },
        dislikeIconColor() {
            if (this.reaction !== -1) {
                return '#bdbdbd'
            }

            if (this.reaction === -1) {
                return this.dislikeIconActive;
            }
        },
        showEditBox() {
            return this.isEdit;
        }
    },
    methods: {
        async likeComment(e) {
            let currentEl = e.currentTarget;
            currentEl.classList.add('cbutton--click');
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            await CommentsAPI.like(this.comment.public_id);

            if (this.reaction === -1) {
                this.dislikes--;
            }

            if (this.reaction === 1) {
                this.reaction = 0;
                this.likes--;
            } else {
                this.reaction = 1;
                this.likes++;
            }
            this.isLoading = false;
            currentEl.classList.remove('cbutton--click');
        },
        async dislikeComment(e) {
            let currentEl = e.currentTarget;
            currentEl.classList.add('cbutton--click');
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            await CommentsAPI.dislike(this.comment.public_id);

            if (this.reaction === 1) {
                this.likes--;
            }

            if (this.reaction === -1) {
                this.reaction = 0;
                this.dislikes--;
            } else {
                this.reaction = -1;
                this.dislikes++;
            }
            this.isLoading = false;
            currentEl.classList.remove('cbutton--click');
        },
        toggleShowEditComment() {
            this.isEdit = !this.isEdit
        }
    }
}
</script>
