<template>
    <div class="upload-box flex-column pb-4 pt-4 mb-3" @drop.prevent="imageDropped" @dragover.prevent>
        <label>
        <span class="upload-icon d-flex justify-content-center">
            <img class="d-block" src="images/icon-upload-2.svg" alt=""/>
        </span>
            <span class="upload-box-bottom d-flex align-items-center flex-column">
                <span class="mt-2 mb-2">Drag and drop an image or video</span>
                <span class="file-btn mt-2 mb-2">Choose files..</span>
                <span class="mt-2 mb-2">or</span>
            </span>
            <input type="file" hidden accept="image/x-png,image/gif,image/jpeg" @change="imageSelected">
        </label>

        <div class="upload-form">
            <div class="form-group m-0 d-flex pl-4 pr-4">
                <input v-model="fileUrl" type="text" class="form-control" id="image-url"
                       placeholder="Paste image or video URL">
                <button class="btn-border full-width ml-3 btn-parse-file" :disabled="isParseFileLoading" @click="parseFileUrl">
                    <i v-if="isParseFileLoading" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else>Paste</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
const fileReader = new FileReader();
export default {
    name: "PostMediaUpload",

    data() {
        return {
            fileUrl: ''
        }
    },

    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        isParseFileLoading() {
            return this.isLoading
        }
    },

    methods: {
        imageDropped(e) {
            this.processUploadedImage(e.dataTransfer.files[0]);
        },
        imageSelected(e) {
            this.processUploadedImage(e.target.files[0]);
        },
        processUploadedImage(file) {
          return this.$emit('uploadedFile', file);
        },
        parseFileUrl() {
            return this.$emit('parseFile', this.fileUrl);
        },
    }
}
</script>

<style scoped>
    .btn-parse-file {
        width: 70px;
    }

</style>
