<template>
    <div class="row align-items-center p-0 mr-0">
        <div class="col p-0 mr-1 ml-1 d-none d-xl-flex">
            <a :href="pinterestLink" target="_blank" class="btn-social btn-pintrest"><span class="pintrest"></span>
                pinterest</a>
        </div>
        <div class="col p-0 mr-1 ml-1 d-none d-xl-flex">
            <a :href="facebookLink" target="_blank" class="btn-social btn-facebook"><span class="facebook"></span>
                facebook</a>
        </div>
        <div class="col p-0 ml-1 d-none d-xl-flex">
            <div class="ellipsis-dropdown d-flex col p-0">
                <button class="btn-ellipsis p-0 ml-auto" :class="{'active': showDropdown}"
                        @click="showDropdown = !showDropdown"></button>
                <div class="dropdown-menu" :class="{'show': showDropdown}">
                    <ul>
                        <li v-show="user && user.username === post.username">
                            <a href="#" @click.stop="deletePost"><i class="report"></i> Delete</a>
                        </li>
                        <li><a href="#" @click.stop="copyLink"><i class="link"></i> Copy Link</a></li>
                        <li><a :href="twitterLink"><i class="twitter"></i> Twitter</a></li>
                        <li class="d-flex d-xl-none"><a :href="pinterestLink" target="_blank" class="">
                            <icon name="icon-pintrest"/>
                            Pinterest</a></li>
                        <li class="d-flex d-xl-none"><a :href="facebookLink" target="_blank" class="">
                            <icon name="icon-facebook"/>
                            Facebook</a></li>
                        <li v-show="!post.is_video"><a href="#" @click.stop="downloadImage"><i class="download"></i>
                            Download</a></li>
                        <li v-if="canReport"><a href="#" @click.prevent="reportPost"><i class="report"></i>Report</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "./Icon";

export default {
    name: "PostMeta",
    components: {Icon},
    props: {
        post: Object
    },
    data() {
        return {
            showDropdown: false
        }
    },
    created() {
        window.addEventListener('click', (e) => {
            if (!e.target.classList.contains('btn-ellipsis') && !e.target.closest('.btn-ellipsis')) {
                this.showDropdown = false
            }
        });
    },
    computed: {
        pinterestLink() {
            return `https://www.pinterest.com/pin/create/button/` +
                `?url=${encodeURI(this.post.url)}` +
                `&media=${encodeURI(this.post.thumbnail_url)}` +
                `&description=${encodeURI(this.post.title)}`;
        },
        facebookLink() {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.post.url)}`
        },
        twitterLink() {
            return `https://twitter.com/intent/tweet?text=${this.post.title}: ${encodeURI(this.post.url)}`
        },
        user() {
            return this.$store.state.currentUser;
        },
        canReport() {
            return this.$store.state.currentUser ? this.$store.state.currentUser.id !== this.post.user_id : false;
        }
    },
    methods: {
        copyLink(e) {
            e.preventDefault();
            let textArea = document.createElement("textarea");
            textArea.value = this.post.url;
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            this.$toast.info('Link copied!');
        },
        downloadImage(e) {
            e.preventDefault();
            let link = document.createElement("a");
            link.download = this.post.filename;
            link.href = this.post.source;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        deletePost(e) {
            e.preventDefault();
            this.$store.commit('setPostToDelete', this.post.public_id)
        },
        reportPost() {
            this.showDropdown = false;
            this.$store.commit('setPostToReport', this.post.public_id)
        }
    }
}
</script>
