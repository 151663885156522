<template>
    <div class="card-user">
        <a class="d-flex align-items-center" :href="user.profile_url" target="_blank">
            <div class="user-img mr-3">
                <img :src="userAvatar" :alt="user.username"/>
            </div>
            {{user.username}}
            <span v-if="isVerified" class="icon-verified ml-1"></span>
        </a>
    </div>
</template>
<script>
export default {
    name: 'UserInfoCard',

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        isVerified() {
            return this.user.user_is_verified || this.user.is_verified;
        },

        userAvatar() {
            return this.user.avatar_thumbnail ?? this.user.avatar;
        }
    }
}


</script>
