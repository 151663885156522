/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue'
window.Vue = require('vue');
import Vuex from 'vuex'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
require('./bootstrap');

Vue.use(Vuex);
Vue.use(Toast, { hideProgressBar: true })
import storeData from "./store";

const store = new Vuex.Store(storeData)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    el: '#memeheadApp',
    store: store,
    data() {
        return {
            theme: localStorage.getItem('theme') === 'light' ? 'light' : 'dark',
            reactionChanges: {},
            mobileSearchShown: false,
        }
    },
    computed: {
        headerMenuShown() {
            return this.$store.state.headerMenuShown
        },
        mobileCategoriesShown() {
            return this.$store.state.mobileCategoriesShown
        },
        authModalShown() {
            return this.$store.state.authModalShown
        },
        showNotifications() {
            return this.$store.state.headerNotificationsShown;
        },
        user() {
            return this.$store.state.currentUser;
        }
    },
    watch: {
        theme() {
            if (this.theme === 'light') {
                document.body.className = "light-theme";
            } else {
                document.body.className = "dark-theme";
            }
            localStorage.setItem('theme', this.theme);
        }
    },
    created() {
        this.$store.commit('storeUser', window.u)
        if (window.hasErrors) {
            this.showAuthModal();
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('login')) {
            this.showAuthModal();
            window.history.replaceState({}, document.title, "/");
        }

        window.addEventListener('click', (e) => {
           if (!e.target.classList.contains('js-header-dropdown') && !e.target.closest('.js-header-dropdown')) {
               if (this.$store.state.headerMenuShown) {
                   this.toggleHeaderMenu();
               }
               if (this.$store.state.headerNotificationsShown) {
                   this.toggleHeaderNotifications();
               }
           }
        });
    },
    methods: {
        toggleHeaderMenu() {
            this.$store.commit('toggleHeaderMenu');
            this.mobileSearchShown = false
        },
        toggleHeaderNotifications() {
            this.$store.commit('toggleHeaderNotifications')
        },
        showAuthModal() {
            this.$store.commit('showAuthModal')
        },
        hideAuthModal() {
            this.$store.commit('hideAuthModal')
        },
        toggleMobileCategories() {
            this.$store.commit('toggleMobileCategories')
        },
        showSubmitModal() {
            if (window.u) {
                this.$store.commit('showSubmitModal')
            } else {
                this.showAuthModal();
            }
        },
        toggleMobileSearch() {
            this.mobileSearchShown = !this.mobileSearchShown
            if (this.mobileSearchShown) {
                setTimeout(function () {
                    document.getElementById('searchQuery').focus();
                }, 0)
            }
        }
    },
});
