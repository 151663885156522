<template>
    <transition name="fade">
        <div v-show="authModalShown" class="memehead-overlay">
            <div class="modal-box auth">
                <div class="modal-close" @click="hideAuthModal">
                    <button></button>
                </div>
                <div class="modal-container">
                    <div class="modal-social-media-row">
                        <a class="social-connect-button fb-login" href="/auth/facebook">
                            <i>
                                <svg class="svg-icon icon-facebook-share" role="img" height="46" width="46"
                                     viewBox="0 0 46 46">
                                    <path d="M43.5,0H2.5C1.1,0,0,1.1,0,2.5v40.9C0,44.9,1.1,46,2.5,46h22V28.2h-6v-6.9h6v-5.1c0-5.9,3.6-9.2,8.9-9.2c2.5,0,4.7,0.2,5.4,0.3v6.2l-3.7,0c-2.9,0-3.4,1.4-3.4,3.4v4.4h6.9l-0.9,6.9h-6V46h11.7c1.4,0,2.5-1.1,2.5-2.5V2.5C46,1.1,44.9,0,43.5,0z"></path>
                                </svg>
                            </i> <span>Continue with Facebook</span>
                        </a>
                        <a class="social-connect-button google-login" href="/auth/google"> <i>
                            <svg class="svg-icon icon-google-share" role="img" height="26" width="26"
                                 viewBox="0 0 26 26">
                                <path d="M22.9,0H3.1C1.4,0,0,1.4,0,3.1v19.8C0,24.6,1.4,26,3.1,26h19.8c1.7,0,3.1-1.4,3.1-3.1V3.1C26,1.4,24.6,0,22.9,0z M13.2,21C8.6,21,5,17.4,5,13s3.6-8,8.2-8c2.1,0,3.9,0.8,5.4,2l-2.4,2.3c-0.8-0.7-1.9-1.1-3-1.1c-2.7,0-4.8,2.1-4.8,4.7c0,2.6,2.2,4.7,4.8,4.7c2.3,0,4-1.1,4.4-3.1h-4.4v-3.1h7.6c0.1,0.5,0.2,1,0.2,1.5C21,18.1,17.3,21,13.2,21z"></path>
                            </svg>
                        </i> <span>Continue with Google</span> </a>
                        <div class="break clearfix"> <hr> <p class="or">or</p> <hr> </div>
                    </div>
                    <div>
                        <div v-show="authTab === 0">
                            <div>
                                <div class="text-danger">
                                    <div v-if="loginErrors.email">{{ loginErrors.email[0] }}</div>
                                    <div v-if="loginErrors.password">{{ loginErrors.password[0] }}</div>
                                </div>
                                <div>
                                    <input
                                        v-model="loginData.email"
                                        type="text"
                                        class="email"
                                        placeholder="Your Email"
                                        name="email"
                                    >
                                    <input
                                        v-model="loginData.password"
                                        type="password"
                                        class="password"
                                        placeholder="Password"
                                        name="password"
                                    >
                                    <div class="links">
                                        <div class="clearfix">
                                            <div class="remember-me">
                                                <input type="checkbox"
                                                       name="remember"
                                                       data-bind="checked: remember">Remember me
                                            </div>
                                            <a class="forgot-password-link" href="">Forgot password?
                                            </a>
                                        </div>

                                        <button type="submit" class="submit-button" @click="login" :disabled="isLoading">
                                            <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                            <span v-else>Log In</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix">
                                <hr class="form-break">
                            </div>

                            <span class="sign-up-link"> Don't have an account?
                                <a v-on:click="authTab = 1">Sign Up</a>
                            </span>
                        </div>
                        <div v-show="authTab === 1">
                            <div>
                                <div class="text-danger">
                                    <div v-if="registerErrors.username">{{ registerErrors.username[0] }}</div>
                                    <div v-if="registerErrors.email">{{ registerErrors.email[0] }}</div>
                                    <div v-if="registerErrors.password">{{ registerErrors.password[0] }}</div>
                                </div>
                                <div>
                                    <input
                                        v-model="registerData.username"
                                        type="text"
                                        class="username"
                                        placeholder="Your Username"
                                        name="username"
                                    >
                                </div>
                                <div>
                                    <input
                                        v-model="registerData.email"
                                        type="text"
                                        class="email border-bottom-none border-top-none"
                                        placeholder="Your Email"
                                        name="email"
                                    >
                                </div>
                                <div>
                                    <input
                                        v-model="registerData.password"
                                        type="password"
                                        class="password"
                                        placeholder="Password"
                                        name="password"
                                    >
                                </div>
                                <div class="links">
                                    <button type="submit" class="submit-button" @click="register" :disabled="isLoading">
                                        <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                        <span v-else>Sign Up</span>
                                    </button>
                                </div>
                                <span class="tos">Creating an account means you agree with Bored Panda's <a target="_blank" href="/terms-of-service/">Terms of Service</a> and <a target="_blank" href="/privacy-policy/">Privacy Policy</a></span>
                            </div>

                            <div class="clearfix">
                                <hr class="form-break">
                            </div>

                            <span class="sign-up-link">Have an account?
                                <a v-on:click="authTab = 0">Log In</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import _API from "../api/_api";
    import CommentsAPI from "../api/CommentsAPI";

    export default {
        name: "AuthForm",
        data() {
            return {
                isLoading: false,
                authTab: 0,
                loginErrors: {},
                registerErrors: {},
                loginData: {
                    email: "",
                    password: ""
                },
                registerData: {
                    username: "",
                    email: "",
                    password: "",
                },
            }
        },
        computed: {
            authModalShown() {
                return this.$store.state.authModalShown
            },
            getDraftComment() {
                return this.$store.state.draftComment;
            }
        },
        methods: {
            login() {
                this.loginErrors = {};
                this.isLoading = true
                let API = new _API;
                API
                    .post('auth/login', this.loginData)
                    .then(res => {
                        if (this.getDraftComment) {
                            const { comment, commentFile, parentCommentId, postId } = this.getDraftComment;
                            CommentsAPI.create(postId, comment, parentCommentId, commentFile);
                        }
                        document.location.reload();
                    })
                    .catch((err) => {
                        this.loginErrors = err.response.data.responseData.data;
                        console.log(err.response)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            register() {
                this.registerErrors = {};
                this.isLoading = true
                let API = new _API;
                API
                    .post('auth/register', this.registerData)
                    .then(res => {
                        document.location.href = "/";
                    })
                    .catch((err) => {
                        this.registerErrors = err.response.data.responseData.data;
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            hideAuthModal() {
                this.$store.commit('hideAuthModal')
            }
        }
    }
</script>
