<template>
    <div class="col d-flex p-0">
        <div class="col-6 d-flex p-0 align-items-center">
            <a
                @click.prevent="likePost($event)"
                href="#"
                class="d-flex align-items-center like cbutton cbutton--effect-radomir"
                :class="{'reacted' : post.reaction === 1}"
                @mouseover="likeHover = true"
                @mouseleave="likeHover = false"
            >
                <icon
                    name="icon-like"
                    class-name="icon-like"
                    :width=iconWidth
                    :height=iconHeight
                    :color=likeIconColor
                />
                <div class="count">{{ likeCount }}</div>
            </a>
        </div>
        <div class="col-6 d-flex p-0 align-items-center">
            <a @click.prevent="dislikePost($event)"
               href="#"
               class="d-flex align-items-center dislike cbutton cbutton--effect-radomir"
               :class="{'reacted' : post.reaction === -1}"
               @mouseover="dislikeHover = true"
               @mouseleave="dislikeHover = false"
            >
                <icon
                    name="icon-like"
                    class-name="icon-dislike"
                    :width=iconWidth
                    :height=iconHeight
                    :color=dislikeIconColor
                >
                </icon>
                <div class="count">{{ dislikeCount }}</div>
            </a>
        </div>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="like-gradient" x1="20.4775" y1="22" x2="0.510891" y2="20.8435"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.347529" stop-color="#28CBFF"/>
                    <stop offset="0.785055" stop-color="#3AD192"/>
                    <stop offset="1" stop-color="#129F64"/>
                </linearGradient>
            </defs>
        </svg>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="dislike-gradient" x1="3" y1="2" x2="22.8196" y2="19.3194"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E334FA"/>
                    <stop offset="1" stop-color="#FF6A03"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script>
import Icon from "./Icon";
import PostsAPI from "../api/PostsAPI";

export default {
    name: "PostVote",
    components: {Icon},
    props: {
        post: {
            type: Object,
            required: true
        },
        iconSize: {
            type: Object,
            required: false,
            default: function () {
                return {
                    width: '32px',
                    height: '32px'
                }
            },
        }
    },
    data() {
        return {
            postLikes: this.post.likes,
            postReaction: this.post.reaction,
            postDislikes: this.post.dislikes,
            reactionLoading: false,
            likeIconActive: 'url(#like-gradient)',
            dislikeIconActive: 'url(#dislike-gradient)',
            likeHover: false,
            dislikeHover: false
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        },
        likeCount() {
            return this.thousandFormatter(this.postLikes)
        },
        dislikeCount() {
            return this.thousandFormatter(this.postDislikes);
        },
        iconWidth() {
            return this.iconSize.width || this.iconSize.default.width;
        },
        iconHeight() {
            return this.iconSize.height || this.iconSize.default.height;
        },
        likeIconColor() {
            return (this.postReaction === 1 || this.likeHover) ? this.likeIconActive : '#bdbdbd';
        },
        dislikeIconColor() {
            return (this.postReaction === -1 || this.dislikeHover) ? this.dislikeIconActive : '#bdbdbd';
        }
    },

    methods: {
        async likePost(event) {
            let currentEl = event.currentTarget;
            currentEl.classList.add('cbutton--click');
            if (this.user) {
                if (this.reactionLoading) {
                    return;
                }
                this.reactionLoading = true;
                let diff = await PostsAPI.like(this.post.public_id);
                this.postReaction = this.postReaction === 1 ? 0 : 1;
                this.post.score += diff.scoreChange;
                this.postLikes += diff.likesChange;
                this.reactionLoading = false;
            } else {
                this.$store.commit('showAuthModal')
            }
            currentEl.classList.remove('cbutton--click');
        },

        async dislikePost(event) {
            let currentEl = event.currentTarget;
            currentEl.classList.add('cbutton--click');
            if (this.user) {
                if (this.reactionLoading) {
                    return;
                }
                this.reactionLoading = true;
                let diff = await PostsAPI.dislike(this.post.public_id);
                this.postReaction = this.postReaction === -1 ? 0 : -1;
                this.post.score += diff.scoreChange;
                this.postDislikes += diff.dislikesChange;
                this.reactionLoading = false;
            } else {
                this.$store.commit('showAuthModal')
            }
            currentEl.classList.remove('cbutton--click');
        },
        thousandFormatter(num) {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        }
    }
}
</script>

<style scoped>

</style>
