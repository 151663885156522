<template>
    <li :class="{'unread': isUnread}">
        <a v-if="n.type === 'CommentMention'" :href="n.data.post_url + '?cid=' + n.event_source_id">
            <img :src="'/u/' + n.data.username + '/avatar'" alt=""/>
            <span><strong>{{n.data.username}}</strong> mentioned you in a comment</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
        <a v-if="n.type === 'NewPostComment'" :href="n.data.post_url">
            <img :src="'/u/' + n.data.username + '/avatar'" alt=""/>
            <span><strong>{{n.data.username}}</strong> commented on your post</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
        <a v-if="n.type === 'NewFollowerNotification'" :href="'/u/' + n.data.username" target="_blank">
            <img :src="'/u/' + n.data.username + '/avatar'" alt=""/>
            <span><strong>{{n.data.username}}</strong> started following you</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
        <a v-if="n.type === 'PostMention'" :href="n.data.post_url">
            <img :src="'/u/' + n.data.username + '/avatar'" alt=""/>
            <span><strong>{{n.data.username}}</strong> mentioned you in a post</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
        <a v-if="n.type === 'MoreThanOneHundredPostLikes'" :href="n.data.post_url">
            <img src="/images/icon-hot.svg" alt=""/>
            <span>More than 100 people upvoted your post</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
        <a v-if="n.type === 'MoreThanTenPostLikes'" :href="n.data.post_url">
            <img src="/images/icon-hot.svg" alt=""/>
            <span>More than 10 people upvoted your post</span><br>
            <span class="time">{{n.created_ago}}</span>
        </a>
    </li>
</template>

<script>
export default {
    name: "SingleNotification",
    props: {
        n: Object
    },
    computed: {
        isUnread() {
            return !this.n.read_at;
        }

    }
}
</script>
