<template>
    <div>
        <div v-if="showNotifications">
            <ul class="notification-tab-list js-header-dropdown">
                <li v-for="(tab, index) in notificationTabs"
                    @click="handleSwitchNotifications(index)"
                    :class="{active: currentNotificationTab === index}">
                    {{tab}}
                </li>
            </ul>
            <div class="dropdown-menu-notification">
                <div v-if="isLoading" class="text-center p-2">
                    <i class="fa fa-spin fa-circle-o-notch"></i>
                </div>
                <ul>
                    <li v-if="!isLoading && !notificationsData.length" class="no-data">
                        <img src="/images/icon-article.svg" alt=""/>
                        <span>No notifications yet</span><br>
                        <span class="time">&nbsp;</span>
                    </li>
                    <template v-else>
                        <single-notification v-for="n in notificationsData" :n="n" :key="n.id"></single-notification>
                    </template>
                </ul>
            </div>
            <div class="logout-link-box"><a href="/notifications" class="btn-purple-border" v-if="!isPage">see all</a></div>
            <div id="notificationLoader" style="height: 10px; float: left" v-if="isPage"></div>
        </div>
    </div>
</template>

<script>
    import NotificationsAPI from "../api/NotificationsAPI";
    import SingleNotification from "./SingleNotification";

    export default {
        name: "UserNotifications",
        components: {SingleNotification},
        props: ['isPage'],

        data() {
            return {
                hasNewUnreads: false,
                notificationsData: [],
                recentAllNotifications: [],
                recentMentionsNotifications: [],
                isLoading: false,
                currentNotificationTab: 0,
                notificationTabs: ['All', 'Mentions'],
                page: 0,
                loadMore: true
            }
        },
        mounted() {
            if (!this.isPage) {
                this.checkForNew();
            } else {
                const scrollObserver = new IntersectionObserver(((entries, observer) => {
                    if (entries[0].intersectionRatio > 0) {
                        this.getNotifications();
                    }
                }), {
                    root: null,
                    rootMargin: '5px',
                    threshold: 0.5
                });

                scrollObserver.observe(document.querySelector(`#notificationLoader`));
            }
        },
        computed: {
            showNotifications() {
                return this.isPage ? true : this.$store.state.headerNotificationsShown;
            },
        },
        watch: {
            showNotifications: function (val) {
                this.notificationsData = [];
                this.page = 0;
                if (val) {
                   this.getNotifications();
                }
            },
        },
        methods: {
            async checkForNew() {
                this.hasNewUnreads = await NotificationsAPI.hasNew();
                setTimeout(this.checkForNew, 9000);
            },

            getNotifications() {
                this.isLoading = true;

                if (!this.loadMore) {
                    this.isLoading = false;
                    return;
                }

                this.page++;

                if (this.currentNotificationTab === 1) {
                    return this.fetchMentionsNotifications();
                } else {
                    return this.fetchRecentNotifications();
                }
            },

            handleSwitchNotifications(index) {
                this.isLoading = true;
                this.currentNotificationTab = index;
                this.page = 0;
                this.loadMore = true;
                this.notificationsData = [];
                this.getNotifications()
                this.isLoading = false;
            },

            async fetchMentionsNotifications() {
                return await NotificationsAPI.getMentions(this.page).then((res) => {
                    this.handleResponse(res);
                });
            },

            async fetchRecentNotifications() {
                return await NotificationsAPI.getRecent(this.page).then((res) => {
                    this.handleResponse(res);
                });
            },

            handleResponse(res) {
                this.notificationsData.push(...res.data);
                this.isLoading = false;
                if (res.data.length === 0) {
                    return;
                }

                if (res.last_page === this.page) {
                    this.loadMore = false;
                    this.isLoading = false;
                }
            }
        }
    }
</script>
