import _API from "./_api";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parse from 'date-fns/parse'

let API = new _API;

const NotificationsAPI = {
    hasNew() {
        return API
            .get('notifications/new')
            .then(res => res.data.responseData.data.has_new)
    },

    getRecent(page) {
        return API
            .get(`notifications/recent?page=${page}`)
            .then(res => {
                return this.formatResponseData(res);
            });
    },

    getMentions(page) {
        return API
            .get(`notifications/mentions?page=${page}`)
            .then(res => {
                return this.formatResponseData(res);
            });
    },

    formatResponseData(res) {
        res.data.responseData.data = res.data.responseData.data.map((n) => {
            let nDate = parse(
                n.created_at,
                "yyyy-MM-dd'T'HH:mm:ssX",
                new Date()
            );
            n.created_ago = formatDistanceToNow(nDate) + " ago";
            if (typeof n.data !== "object" && n.data !== null) {
                n.data = JSON.parse(n.data);
            }
            return n;
        });
        return res.data.responseData;
    }
}

export default NotificationsAPI;
