import _API from "./_api";

let API = new _API;

const PostsAPI = {
    uploadPost(newPost) {
        let formData = new FormData();
        for (const prop in newPost) {
            if (newPost.hasOwnProperty(prop)) {
                if (newPost[prop] === null || newPost[prop] === '') {
                    continue;
                }
                if (Array.isArray(newPost[prop])) {
                    for (let i = 0; i < newPost[prop].length; i++) {
                        formData.append(prop + "[]", newPost[prop][i]);
                    }
                } else {
                    formData.append(prop, newPost[prop]);
                }
            }
        }

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        return API
            .post('posts', formData, config)
            .then(res => res.data.responseData.data.url)
    },

    like(publicId) {
        return API
            .post(`posts/${publicId}/likes`)
            .then(res => res.data.responseData.data)
    },

    dislike(publicId) {
        return API
            .post(`posts/${publicId}/dislikes`)
            .then(res => res.data.responseData.data)
    },

    getComments(publicId) {
        return API.get(`posts/${publicId}/comments`);
    },

    deletePost(publicId) {
        return API.delete(`posts/${publicId}`);
    }
}

export default PostsAPI;
