<template>
    <div class="row m-0" :class="{'feed-two-columns': twoColumns}">
        <trending-tags v-if="!twoColumns"/>
        <register-verify v-if="!twoColumns && user && !user.email_verified_at"/>
        <div :class="[twoColumns ? 'col-lg-6 post-col' : 'col-lg-12 p-0' ]" :key="post.public_id" v-for="post in posts">
            <single-post
                :post="post"
                :showFollowButton="showFollowButton"
                :showNoPostsInfo="showNoPostsInfo"
            />
        </div>
        <div v-show="isLoading" class="load-more-row">
            <a href="#" class="btn-load-more"><span><i style=""></i></span></a>
        </div>
        <div
            v-if="showNoPostsInfo"
            v-show="attemptedLoading && !firstLoadPostCount"
            class="post-card p-5"
        >
            <div>
                <h1 class="mb-3">Whoops</h1>

                <div v-if="src === 'feeds/followed'">
                    Seems like you aren't following anyone yet! Follow a user or two and their posts will show up here.
                </div>
                <div v-else>No posts currently</div>
            </div>
        </div>
        <div id="postLoader" style="height: 10px; float: left"></div>
    </div>
</template>

<script>
import NewsfeedAPI from "../api/NewsfeedAPI";
import FollowButton from "./FollowButton";
import UserInfoCard from "./UserInfoCard";
import RegisterVerify from "./RegisterVerify";
import TrendingTags from "./TrendingTags";
import Icon from "./Icon";
import SinglePost from "./SinglePost";

export default {
    name: "Newsfeed",
    components: {SinglePost, Icon, TrendingTags, RegisterVerify, UserInfoCard, FollowButton},
    props: {
        src: String,
        twoColumns: {
            type: Boolean,
            default: false
        },
        showFollowButton: {
            type: Boolean,
            default: true
        },
        showNoPostsInfo: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            posts: [],
            now: parseInt(Math.round((new Date()).getTime() / 1000)),
            page: 0,
            scrollObserver: null,
            isLoading: false,
            attemptedLoading: false,
            lastLoadPostCount: -1,
            firstLoadPostCount: -1,
            reactionLoading: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        }
    },
    mounted() {
        const scrollObserver = new IntersectionObserver(((entries, observer) => {
            if (entries[0].intersectionRatio > 0) {
                this.getPosts();
            }
        }), {
            root: null,
            rootMargin: '5px',
            threshold: 0.5
        });

        scrollObserver.observe(document.querySelector(`#postLoader`));
    },
    methods: {
        getPosts() {
            if (this.isLoading) {
                return;
            }

            if (this.attemptedLoading && this.lastLoadPostCount === 0) {
                // no more posts in this feed
                return;
            }

            this.isLoading = true;
            this.page++;
            return NewsfeedAPI
                .getNewsfeed(this.src, this.now, this.page)
                .then((posts) => {
                    this.lastLoadPostCount = posts.length;
                    if (this.firstLoadPostCount === -1) {
                        this.firstLoadPostCount = posts.length;
                    }
                    this.posts = this.posts.concat(posts)
                })
                .finally((f) => {
                    this.$nextTick(() => {
                        this.isLoading = false;
                        this.attemptedLoading = true;
                    })
                })
        },
    }
}
</script>
