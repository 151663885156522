<template>
    <div>
        <div class="comment-section">
            <h2>{{comments.length ? 'Comments' : 'No comments yet'}}</h2>
            <ul>
                <li v-for="comment in comments">
                    <div class="comment-main-row">
                        <single-comment
                            :comment="comment"
                            :show-load-replies="true"
                            @get-replies-clicked="getCommentReplies(comment.public_id)"
                            @reply-clicked="replyToComment(comment.public_id, comment.user.username)"
                        ></single-comment>
                        <div v-for="(childComment) in comment.commentReplies" class="comment-sub-row">
                            <single-comment
                                :comment="childComment"
                                :show-load-replies="false"
                                @reply-clicked="replyToComment(comment.public_id, comment.user.username)"
                            ></single-comment>
                        </div>
                    </div>
                    <submit-comment
                        v-if="replyToCommentPublicId === comment.public_id"
                        :parent-comment-id="comment.public_id"
                        :reply-to-username="replyToUsername"
                        :post-id="postId"
                        @cancel="replyToCommentPublicId = null; replyToUsername = null"
                        @submitted="commentSubmitted"
                    ></submit-comment>
                </li>
            </ul>
        </div>
        <div class="all-comment-row-wrp" v-if="commentsMeta.last_page > commentsMeta.current_page">
            <div class="all-comment-row">
                <a href="#" @click.prevent="getPostComments">Load more comments</a>
            </div>
        </div>
        <submit-comment
            v-if="!replyToCommentPublicId"
            :post-id="postId"
            class="no-border"
            @submitted="commentSubmitted"
        ></submit-comment>
    </div>
</template>

<script>
    import CommentsAPI from "../api/CommentsAPI";
    import SubmitComment from "./SubmitComment";
    import SingleComment from "./SingleComment";

    export default {
        name: "PostComments",
        components: {SingleComment, SubmitComment},
        props: {
            postId: String
        },
        data() {
            return {
                isLoading: false,
                commentsMeta: {
                    current_page: 0,
                    last_page: 0,
                    total: 0
                },
                comments: [],
                newComment: "",
                replyToCommentPublicId: null,
                replyToUsername: null,
                commentReactionLoading: false,
            }
        },
        computed: {
            user() {
                return this.$store.state.currentUser
            }
        },
        created() {
            this.getPostComments();
        },
        mounted() {
            if (window.location.hash === '#comments') {
                const commDiv = document.querySelector('.comment-section');
                if (!commDiv) {
                    return;
                }
                window.scroll({
                    top: commDiv.getBoundingClientRect().top - 70,
                    behavior: 'smooth'
                });
            }
        },
        methods: {
            getPostComments() {
                this.commentsMeta.current_page++;
                CommentsAPI
                    .getPostComments(this.postId, this.commentsMeta.current_page)
                    .then((res) => {
                        for (const [i, c] of res.data.entries()) {
                            res.data[i].commentReplies = [];
                        }
                        this.comments = this.comments.concat(res.data);
                        delete res.data;
                        this.commentsMeta = res;
                    })
            },
            getCommentReplies(publicId) {
                CommentsAPI
                    .getCommentReplies(publicId)
                    .then((res) => {
                        for (const [i, c] of this.comments.entries()) {
                            if (c.public_id === publicId) {
                                this.comments[i].commentReplies = res.data;
                                break;
                            }
                        }
                    })
            },
            replyToComment(parentCommentPublicId, childCommentAuthorUsername) {
                if (!this.user) {
                    this.$store.commit('showAuthModal');
                    return;
                }
                this.replyToCommentPublicId = parentCommentPublicId;
                this.replyToUsername = childCommentAuthorUsername;
            },
            commentSubmitted(newComment) {
                if (this.replyToCommentPublicId) {
                    this.getCommentReplies(this.replyToCommentPublicId);
                } else {
                    newComment.created_ago = "less than a minute ago";
                    newComment.commentReplies = [];
                    this.comments.push(newComment);
                }

                let labelEl = document.getElementById('post-comments-' + this.postId);
                let totalCount = parseInt(labelEl.dataset.comments) + 1;

                labelEl.innerText = totalCount + " comment" + (totalCount !== 1 ? 's' : '');
                this.replyToCommentPublicId = null;
                this.replyToUsername = null
            }
        }
    }
</script>
